<i18n>
ru:
  cancelPopupTitle: Отменить заказ?
  cancelWarning: Заказ будет отменен без возможности удаления.
  confirmCancel: 'Да, отменить'
  goBack: Вернуться
ua:
  cancelPopupTitle: Скасувати замовлення?
  cancelWarning: Замовлення буде скасовано без можливості видалення.
  confirmCancel: 'Так, скасувати'
  goBack: Повернутися
us:
  cancelPopupTitle: Cancel order?
  cancelWarning: Order will be canceled without possibility of deletion.
  confirmCancel: 'Yes, cancel'
  goBack: Go back
</i18n>

<template>
  <template v-if="orders.length > 0">
    <home-check-order-mirrormere
      v-if="appConfig.VueSettingsPreRun.CheckCurrentOrderLayout === 'Mirrormere'"
      :orders="orders"
      :cancel-order="cancelOrder"
      :go-to-order="goToOrder"
    />
  </template>
</template>

<script setup lang="ts">
import type { OrderInfoForCancel } from '~types/props'

import { type GUID, useCommon } from '@arora/common'

const appConfig = useAppConfig()

const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()
const { dedupeArrayByID, stringIsNullOrWhitespace } = useCommon()
const { checkIfStatusInWork } = useOrderInfo()

const savedOrderIds = useCookie('savedOrderIds', {
  default: () => [] as GUID[],
  expires: new Date(Date.now() + 1728e5)
})

const removeOrderIdAfter = useCookie('removeOrderIdAfter', {
  default: () => {
    return {} as Record<GUID, Date>
  }
})

const orders = ref<OrderInfoForCancel[]>([])

async function refreshOrders(): Promise<void> {
  const orderInfos = [] as OrderInfoForCancel[]
  const promises = [] as Promise<void>[]

  for (const orderId of savedOrderIds.value) {
    promises.push(
      clientStore.loadOrderInfo(orderId).then((orderInfo) => {
        if (orderInfo) orderInfos.push({ ...orderInfo, CanBeCancelled: false })
      })
    )
  }

  await Promise.all(promises)

  orders.value = dedupeArrayByID([
    ...orderInfos,
    ...(clientStore.OrdersForCancel.data ?? []).map((orderInfo) => {
      return { ...orderInfo, CanBeCancelled: true }
    })
  ])
}

onMounted(async () => {
  await clientStore.loadOrdersForCancel()

  if (savedOrderIds.value?.length > 0) {
    await refreshOrders()

    setTimeout(updateStatus, 5000)
  }
})

function updateStatus(): void {
  for (const orderId of savedOrderIds.value) {
    const indexOfOrder = orders.value.findIndex((order) => order.ID === orderId)

    if (indexOfOrder === -1) continue

    if (!stringIsNullOrWhitespace(orders.value[indexOfOrder].TipsLink)) {
      clientStore.refreshOrderStatus(orderId).then((newOrderStatus) => {
        if (
          orders.value[indexOfOrder].TipsLink !== newOrderStatus.TipsLink &&
          !stringIsNullOrWhitespace(newOrderStatus.TipsLink) &&
          !removeOrderIdAfter.value[orderId]
        ) {
          removeOrderIdAfter.value[orderId] = new Date(
            Date.now() +
              appConfig.VueSettingsPreRun.CheckCurrentOrderTimeoutMin * 60000
          )
          showTipsPopup(newOrderStatus.TipsLink)
        }

        orders.value[indexOfOrder].Status = newOrderStatus.Status
        orders.value[indexOfOrder].StatusTitle = newOrderStatus.StatusTitle
        orders.value[indexOfOrder].TipsLink = newOrderStatus.TipsLink

        let index = savedOrderIds.value.indexOf(orderId)

        if (
          (index >= 0 &&
            //if it doesn't contain TipsLink and status is done we are removing it from check
            !checkIfStatusInWork(newOrderStatus) &&
            stringIsNullOrWhitespace(newOrderStatus.TipsLink)) ||
          //if it is time to remove order
          (!!removeOrderIdAfter.value[orderId] &&
            removeOrderIdAfter.value[orderId] > new Date())
        ) {
          savedOrderIds.value.splice(index, 1)
        }
      })
    }
  }
}

async function showTipsPopup(tipsLink: string): Promise<void> {
  popupStore.openPopup({
    popupClosable: true,
    popupName: 'orderTipsPopup',
    popupProperties: new Map<string, unknown>([['tipsLink', tipsLink]])
  })
}

watch(savedOrderIds, (newValue, oldValue) => {
  if (newValue && newValue.length !== (oldValue?.length ?? 0)) refreshOrders()
})

async function cancelOrder(orderId: GUID): Promise<void> {
  try {
    await popupStore.showConfirm({
      message: translate('checkOrderPage.cancelWarning'),
      noText: translate('checkOrderPage.goBack'),
      title: translate('checkOrderPage.cancelPopupTitle'),
      type: 'warning',
      yesFunction: () => {
        clientStore.cancelOrder(orderId)
      },
      yesOrNo: true,
      yesText: translate('checkOrderPage.confirmCancel')
    })
  } catch (error) {
    if (error?.message) await popupStore.showError(error.message)
  } finally {
    await clientStore.loadOrdersForCancel()
  }
}

async function goToOrder(orderId: GUID): Promise<void> {
  await navigateTo(`${appConfig.VueSettingsPreRun.Links.CartFinalStep}/${orderId}`)
}
</script>
